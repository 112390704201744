/*
        SUMMARY
            0 - Const / Variables
            1 - Function
            2 - Sliders
            3 - Modal video
            4 - Cards High
*/

const { speed } = require("jquery");


/*---------------------/ 0 - Const - Variables /-------------------*/


/*---------------------/ 1 - Functions /-------------------*/




/*---------------------/ 2 - Sliders /-------------------*/

// Initialize Swiper
const sliderHome = new Swiper('.heroBannerSlider', {
    loop: true,
    speed: 1500,
    allowTouchMove: false, // Disable swiping
    autoplay: {
        delay: 7000,
    },
    navigation: {
        nextEl: '.next-arrow',
        prevEl: '.prev-arrow',
    },
    on: {
        slideChangeTransitionStart: function () {
            const direction = this.activeIndex > this.previousIndex ? 1 : -1;
            const previousIndex = this.previousIndex;
            const activeIndex = this.activeIndex;
            const slides = this.slides;

            // Get the current and upcoming slides and their inner elements
            const currentSlide = slides[previousIndex].querySelector('.box_slider-content');
            const upcomingSlide = slides[activeIndex].querySelector('.box_slider-content');

            // Define the GSAP animation timeline
            gsap.timeline({
                defaults: { duration: 1.5, ease: 'power3.inOut' },
            })
            .addLabel('start', 0)
            .to(currentSlide, {
                startAt: { transformOrigin: direction === 1 ? '100% 50%' : '0% 50%' },
                scaleX: 4,
                ease: 'power3.inOut'
            }, 'start')
            .fromTo(upcomingSlide, {
                transformOrigin: direction === 1 ? '0% 50%' : '100% 50%',
                scaleX: 4
            }, {
                scaleX: 1,
                ease: 'power3.inOut'
            }, 'start');
        }
    }
});


const sliderProduct = new Swiper('.swiperProduct', {
    loop: false,
    speed: 1500,
    slidesPerView: 4.8,
    spaceBetween: 27,
    autoplay: true,
    breakpoints: {
        0: {
            slidesPerView: 'auto',
            spaceBetween: 20,
        },
        768: {
             slidesPerView: 'auto',
             spaceBetween: 20,
        },
        1200: {
            slidesPerView: 3.2
        },
        1500: {
            slidesPerView: 4
        },
        1800: {
            slidesPerView: 4.8
        }
    }
});

const sliderPartner = new Swiper('.slider_partner', {
    loop: false,
    speed: 1500,
    autoplay:true,
    spaceBetween: 60,
    slidesPerView: 'auto',
    centeredSlides: false,
    breakpoints: {
        0: {
            slidesPerView: 'auto',
            spaceBetween: 32,
        },
        768: {
             slidesPerView: 'auto',
             spaceBetween: 32,
        }
    }
})

const sliderBlog = new Swiper('.blogSlider', {
    loop: true,
    speed: 1500,
    // autoplay:true,
    spaceBetween: 32,
    slidesPerView: 'auto',
})

/*---------------------/ 3 - Modal video /-------------------*/

const glightbox = GLightbox();

/*---------------------/ 4 - Cards High /-------------------*/

window.addEventListener('load', () => {
    const cards = document.querySelectorAll('.blog--info');
    let maxHeight = 0;

    // Find the tallest card
    cards.forEach(card => {
      if (card.offsetHeight > maxHeight) {
        maxHeight = card.offsetHeight;
      }
    });

    // Set all cards to the height of the tallest card
    cards.forEach(card => {
      card.style.height = `${maxHeight}px`;
    });
  });

  document.querySelector('.scroll-down').addEventListener('click', function() {
    // Calculate 100vh in pixels
    const viewportHeight = window.innerHeight;

    // Scroll down by 100vh
    window.scrollBy({
      top: viewportHeight, // 100vh
      behavior: 'smooth' // Optional: for smooth scrolling
    });
  });
